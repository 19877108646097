<div class="account-pages mt-5 mb-5">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-12">
                <div class="text-center">
                    <a href="/" class="mb-5 d-block auth-logo">
                        <img
                            src="assets/images/logo-dark.png"
                            alt=""
                            height="22"
                            class="logo logo-dark"
                        />
                        <img
                            src="assets/images/logo-light.png"
                            alt=""
                            height="22"
                            class="logo logo-light"
                        />
                    </a>
                </div>
            </div>
        </div>
        <div class="row align-items-center justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card overflow-hidden">
                    <div class="card-body p-4">
                        <div class="text-center mt-2">
                            <h5 class="text-primary">Register Account</h5>
                            <p class="text-muted">
                                Get your {{ configuration.application.name }} account now.
                            </p>
                        </div>
                        <div class="p-2 mt-4">
                            <form (ngSubmit)="register()">
                                <!-- <ngb-alert
                                    type="danger"
                                    *ngIf="response"
                                    [dismissible]="false"
                                    >{{ response }}</ngb-alert
                                > -->
                                <div class="mb-3">
                                    <label for="company">Company Name</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="register-nucode"
                                        placeholder="Company"
                                        [(ngModel)]="fields.nucode"
                                        [ngModelOptions]="{standalone: true}"
                                        (keyup)="checkFormLengthRequired('this.fields.nucode', 'register-nucode', 'response-nucode', 2, 50)"
                                    />
                                    <div
                                        *ngIf="submit"
                                        class="invalid-feedback"
                                    >
                                        <div>Company is required</div>
                                    </div>
                                    <div id="response-nucode"></div>
                                </div>
                                <div class="mb-3">
                                    <label for="name">Name</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="register-name"
                                        placeholder="Name"
                                        [(ngModel)]="fields.name"
                                        [ngModelOptions]="{standalone: true}"
                                        (keyup)="checkFormLengthRequired('this.fields.name', 'register-name', 'response-name', 3, 50)"
                                    />
                                    <div
                                        *ngIf="submit"
                                        class="invalid-feedback"
                                    >
                                        <div>Name is required</div>
                                    </div>
                                    <div id="response-name"></div>
                                </div>
                                <div class="mb-3">
                                    <label for="email">Email</label>
                                    <input
                                        type="email"
                                        class="form-control"
                                        id="register-contact-email"
                                        placeholder="Email"
                                        [(ngModel)]="fields.email"
                                        [ngModelOptions]="{standalone: true}"
                                        (keyup)="checkFormEmail('this.fields.email', 'register-contact-email', 'response-contact-email')"
                                    />
                                    <div
                                        *ngIf="submit"
                                        class="invalid-feedback"
                                    >
                                        <div>Email is required</div>
                                    </div>
                                    <div id="response-contact-email"></div>
                                </div>
                                <div class="mb-3">
                                    <label for="username">Username</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="register-username"
                                        placeholder="username"
                                        [(ngModel)]="fields.username"
                                        [ngModelOptions]="{standalone: true}"
                                        (keyup)="checkFormLengthRequired('this.fields.username', 'register-username', 'response-username', 3, 20)"
                                    />
                                    <div
                                        *ngIf="submit"
                                        class="invalid-feedback"
                                    >
                                        <div>Username is required</div>
                                    </div>
                                    <div id="response-username"></div>
                                </div>
                                <div class="mb-3">
                                    <label for="password">Password</label>
                                    <input
                                        type="password"
                                        class="form-control"
                                        id="register-password"
                                        placeholder="Password"
                                        [(ngModel)]="fields.password"
                                        [ngModelOptions]="{standalone: true}"
                                        (keyup)="checkFormPassword('this.fields.password', 'register-password', 'response-password')"
                                    />
                                    <div
                                        *ngIf="submit"
                                        class="invalid-feedback"
                                    >
                                        <div>Password is required</div>
                                    </div>
                                    <div id="response-password"></div>
                                </div>
                                <div class="mb-3">
                                    <label for="confirm_password">Confirm Password</label>
                                    <input
                                        type="password"
                                        class="form-control"
                                        id="register-password-confirm"
                                        placeholder="Confirm Password"
                                        [(ngModel)]="confirm_password"
                                        [ngModelOptions]="{standalone: true}"
                                        (keyup)="checkFormPasswordConfirm('this.confirm_password', 'this.fields.password', 'register-password-confirm', 'response-password-confirm')"
                                    />
                                    <div
                                        *ngIf="submit"
                                        class="invalid-feedback"
                                    >
                                        <div>Password is required</div>
                                    </div>
                                    <div id="response-password-confirm"></div>
                                </div>
                                <div class="mt-3 text-end">
                                    <button
                                        class="btn btn-primary w-sm waves-effect waves-light"
                                        type="submit"
                                    >
                                        Register
                                    </button>
                                </div>
                                <div
                                    class="mt-4 text-center"
                                >
                                    <p class="mb-0">
                                       Already have an account ?
                                        <a
                                            class="fw-medium text-primary"
                                            [routerLink]="['/login']"
                                            >Login Now</a
                                        >
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="mt-5 text-center">
                    <p>
                        © 2021 - {{ year }}
                        {{ configuration.application.name }}. Crafted with
                        <i class="mdi mdi-heart text-danger"></i> by Nutty
                        Developer
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
