import {User} from './user';

export const USERS: User[] = [
    {
      id: '#MN0132',
      name: 'Simon Ryles',
      image: 'assets/images/users/avatar-2.jpg',
      position: 'Full Stack Developer',
      email: 'SimonRyles@minible.com',
      index: 1,
    },
    {
      id: '#MN0131',
      name: 'Marion Walker',
      image: 'assets/images/users/avatar-3.jpg',
      position: 'Frontend Developer',
      email: 'MarionWalker@minible.com',
      index: 2,
    },
    {
      id: '#MN0130',
      name: 'Frederick White',
      position: 'UI/UX Designer',
      email: 'FrederickWhite@minible.com',
      index: 3,
    },
    {
      id: '#MN0129',
      name: 'Shanon Marvin',
      image: 'assets/images/users/avatar-4.jpg',
      position: 'Backend Developer',
      email: 'ShanonMarvin@minible.com',
      index: 4,
    },
    {
      id: '#MN0128',
      name: 'Mark Jones',
      position: 'Frontend Developer',
      email: 'MarkJones@minible.com',
      index: 5,
    },
    {
      id: '#MN0127',
      name: 'Janice Morgan',
      image: 'assets/images/users/avatar-5.jpg',
      position: 'Backend Developer',
      email: 'JaniceMorgan@minible.com',
      index: 6,
    },
    {
      id: '#MN0126',
      name: 'Patrick Petty',
      image: 'assets/images/users/avatar-7.jpg',
      position: 'UI/UX Designer',
      email: 'PatrickPetty@minible.com',
      index: 7,
    },
    {
      id: '#MN0125',
      name: 'Marilyn Horton',
      image: 'assets/images/users/avatar-8.jpg',
      position: 'Backend Developer',
      email: 'MarilynHorton@minible.com',
      index: 8,
    },
    {
      id: '#MN0124',
      name: 'Neal Womack',
      image: 'assets/images/users/avatar-2.jpg',
      position: 'Full Stack Developer',
      email: 'NealWomack@minible.com',
      index: 9,
    },
    {
      id: '#MN0123',
      name: 'Steven Williams',
      position: 'Frontend Developer',
      email: 'StevenWilliams@minible.com',
      index: 10,
    },
    {
      id: '#MN0122',
      name: 'Connie Franco',
      image: 'assets/images/users/avatar-2.jpg',
      position: 'Full Stack Developer',
      email: 'ConnieFranco@minible.com',
      index: 11,
    },
    {
      id: '#MN0121',
      name: 'Lara Casillas',
      image: 'assets/images/users/avatar-2.jpg',
      position: 'Full Stack Developer',
      email: 'LaraCasillas@minible.com',
      index: 12,
    },
  ];